@import '../../RbKit/config';

.thumbnail {
    @include elevate(2);
    background: $colorWhite;
    border-radius: 2px;
    background-position: 50% 0;
    background-repeat: no-repeat;
    background-size: cover;
    display: inline-block;
    width: 150px;
    padding-bottom: 56.25%;
    position: relative;
    margin: .5rem;

    .menu {
        bottom: 5px;
        position: absolute;
        right: 5px;
        z-index: 2;
    }
}
