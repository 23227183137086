.segment {
    max-width: 646px;
    padding-right: 70px;
    @media (max-width: 1024px) {
        max-width: 546px;
    }
}

.container {
    padding: 5px 0;
    border-top: solid 1px #000000;
    transition: border 150ms linear;
    
    .title {
        cursor: pointer;
        margin: 0;
        align-items: flex-start;
        display: flex;

        svg {
            color: #f16825;
            font-size: 24px;
            margin-right: 10px;
            line-height: 1;
            position: relative;
            top: -1px;
        }
    }

    .content {
        max-height: 0;
        overflow: hidden;
        padding: 5px 25px 0 19px;
        transition: max-height 200ms ease-out, background 200ms ease-out;

        h1 {
            color: #f16825;
            margin: 40px 0 50px 0;
            font-size: 36px;
        }
        
        a {
            color: #f16825;
        }
        
        p, ul, ol, h2 {
            font-size: 15px;
            line-height: 1.5;
            margin-bottom: 24px;
        }
        
        h2 {
            font-size: 18px;
            margin-bottom: 5px;
        }
        
        p + ul, p + ol {
            margin-top: -24px;
        }
    }

    &.collapsed {
        // background-color: rgba(242, 101, 34, .1);

        .title {
            font-weight: bold;
        }

        .content {
            max-height: var(--max-height);
        }
    }
}
