.container {
    flex: 1;
    order: 2;
    margin-left: 2rem;
    max-width: 646px;
    padding-right: 70px;
    @media (max-width: 1024px) {
        max-width: 546px;
    }

    h1 {
        color: #f16825;
        margin: 40px 0 50px 0;
        font-size: 36px;
    }

    a {
        color: #f16825;
    }

    p, ul, ol, h2 {
        font-size: 15px;
        line-height: 1.5;
        margin-bottom: 24px;
        
        > span {
            color: #f16825;
        }
    }

    h2 {
        font-size: 18px;
        margin-bottom: 5px;
    }

    p + ul, p + ol {
        margin-top: -24px;
    }
}

.thumb {
    order: 1;
}

.segment {
    display: flex;
    align-items: flex-start;

    &.right {
        .thumb {
            order: 2;
        }
        .container {
            order: 1;
            margin: 0 2rem 0 0;
        }
    }
}
