@import '../../RbKit/config';

.counter {
    color: $colorGrey30;
    font-size: .75rem;
    margin: -1.15rem 0 1rem 0;
}

.counterError {
    color: $colorError;
}

.preview {
    .previewTitle {
        display: block;
        color: #1a0dab;
        cursor: pointer;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 18px;
        line-height: 1.2;
    }

    .previewUrl {
        display: block;
        font-size: 14px;
        line-height: 16px;
        color: #006621;
    }

    .previewCopy {
        color: #545454;
        font-size: 13px;
        line-height: 1.4;
        word-wrap: break-word;
    }
}
