.segment {
    max-width: 646px;
    padding-right: 70px;
    @media (max-width: 1024px) {
        max-width: 546px;
    }
}

.container {
    align-items: center;
    display: flex;
    justify-content: space-between;

    > div {
        display: flex;
        flex: 1;

        h3 {
            font-size: 18px;
            line-height: 1.5;
            margin-bottom: 0;
            flex: 1;
        }

        svg {
            color: #f16825;
            font-size: 24px;
            margin-right: 10px;
            line-height: 1;
            position: relative;
            top: 3px;
        }
    }

    span {
        display: inline-block;
        color: #f16825;
        font-size: 14px;
        line-height: 1;
        padding: 5px 12px;
        background-color: #ffffff;
        border-radius: 10px;
        box-shadow: 2px 2px 5px rgba(0, 0, 0, .1);
    }
}
